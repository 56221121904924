import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import logoSrc from "../images/biobeechoshotel-logo.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <img src={logoSrc} alt="404: Not found" width={144} height={112} />
    <h1>404</h1>
    <p>la página que estás buscando no existe o ha cambiado</p>
    <Link to="/">Pruebe la página de inicio</Link>
  </Layout>
)

export default NotFoundPage
